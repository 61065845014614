// import React, { useState } from 'react';
// import {
//   AdvancedMarker,
//   InfoWindow,
//   Pin,
//   useAdvancedMarkerRef
// } from '@vis.gl/react-google-maps';

// export const MarkerWithInfowindow = (props) => {
//   const [infowindowOpen, setInfowindowOpen] = useState(false);
//   const [markerRef, marker] = useAdvancedMarkerRef();

//   return (
//     <>
//       <AdvancedMarker
//         ref={markerRef}
//         onClick={() => setInfowindowOpen(true)}
//         position={{ lat: Number(props.data.latitude), lng: Number(props.data.longitude) }}
//         title={props.data.storeName}
//       >
//         {props.data.storeType === "EBO" ? (
//           <Pin
//             background={'#FF8931'}
//             borderColor={'#FF8931'}
//             glyphColor={'#ffffff'}
//           />
//         ) : (
//           <Pin
//             background={'#513117'} // Default color
//             borderColor={'#513117'} // Default border color
//             glyphColor={'#ffffff'} // Default glyph color
//           />
//         )}
//       </AdvancedMarker>

//       {infowindowOpen && (
//         <InfoWindow
//           anchor={marker}
//           onCloseClick={() => setInfowindowOpen(false)}
//           className='bs-info-window'
//         >
//           <p className='bs-info-window__item-name'>{props.data.storeName}</p>
//           <address className='bs-info-window__item-address'>
//             {`${props.data.fullAddress} ${props.data.city} ${props.data.state} ${props.data.pinCode}`}
//           </address>
//           <p className='bs-info-window__item-time'>Contact - {props.data.contactNumber}</p>
//           {/* <strong className='bs-info-window__item-distance'>Link - {props.data.googleMapLink}</strong> */}
//         </InfoWindow>
//       )}
//     </>
//   );
// };

import React from 'react';
import {
  AdvancedMarker,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';

export const MarkerWithInfowindow = ({ data, isOpen, onClick }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  // const openInfoWindow = (markerId) => {
  //   if(props.index === markerId){
  //     setInfowindowOpen(true);
  //   }else{
  //     setInfowindowOpen(false);
  //   }
    
  // }

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => onClick(data)}
        position={{ lat: Number(data.latitude), lng: Number(data.longitude) }}
        title={data.storeName}
      >
        {data.storeType === "EBO" ? (
          <Pin
            background={'#FF8931'}
            borderColor={'#FF8931'}
            glyphColor={'#ffffff'}
          />
        ) : (
          <Pin
            background={'#513117'} // Default color
            borderColor={'#513117'} // Default border color
            glyphColor={'#ffffff'} // Default glyph color
          />
        )}
      </AdvancedMarker>

      {isOpen && (
        <InfoWindow
          anchor={marker}
          onCloseClick={() => onClick(null)}
          className='bs-info-window'
        >
          <p className='bs-info-window__item-name'>{data.storeName}</p>
          <address className='bs-info-window__item-address'>
            {`${data.fullAddress} ${data.city} ${data.state} ${data.pinCode}`}
          </address>
          <p className='bs-info-window__item-time'>Contact - {data.contactNumber}</p>
          {/* <strong className='bs-info-window__item-distance'>Link - {data.googleMapLink}</strong> */}
        </InfoWindow>
      )}
    </>
  );
};
