import React, { useCallback, useEffect, useState } from 'react';
import SearchResult from './components/SearchResult';
import SearchFilter from './components/SearchFilter';
import useApi from '@common/utils/useApi';
import { APIProvider, Map, ControlPosition } from '@vis.gl/react-google-maps';
import { CustomMapControl } from './components/CustomMapControl';
import MapHandler from './components/MapHandler';
import { MarkerWithInfowindow } from './components/MarkerWithInfowindow';
import PlaceAutocomplete from './components/PlaceAutocomplete';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const indiaCenter = {
    lat: 20.5937, // Latitude for the center of India
    lng: 78.9629  // Longitude for the center of India
};

const INITIAL_CAMERA_STATE = {
    center: {lat: 20.5937, lng: 78.9629},
    zoom: 5,
    heading: 0,
    tilt: 0
}

const autocompleteModes = [
    {id: 'classic', label: 'Google Autocomplete Widget'},
    {id: 'custom', label: 'Custom Build'},
    {id: 'custom-hybrid', label: 'Custom w/ Select Widget'}
];

function WhereToBuy() {
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyBKLFaOo3qUrrLmnWyjgbgnfcZTTfVNu20",
    //     // mapIds: ["570347ebde0a874f"]
    // })
    // const [map, setMap] = React.useState(null);

    // const onLoad = React.useCallback(function callback(map) {
    //     // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //     const bounds = new window.google.maps.LatLngBounds(indiaCenter);
    //     map.fitBounds(bounds);
    
    //     setMap(map)
    // }, [])

    // const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    // }, [])

    const [selectedAutocompleteMode, setSelectedAutocompleteMode] = useState(autocompleteModes[2]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [storeListData, setStoreListData] = useState([]);
    const [userLocation, setUserLocation] = useState(indiaCenter);
    const [mapZoom, setMapZoom] = useState(5);
    const [activeMarker, setActiveMarker] = useState(null);
    const [cameraState, setCameraState] = useState(INITIAL_CAMERA_STATE);

    const url = `https://sansaar.co.in/api/v1/util/store`;
    const { data: response, loading: isLoading, error } = useApi(url);

    useEffect(() => {
        if (!isLoading && response) {
            setStoreListData(response);
        }
    }, [isLoading, response]);

    const handleDetectLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                updateCenter({ lat: latitude, lng: longitude })
                updateZoom(12);
            }, error => {
                console.error("Error getting location", error);
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const handleMarkerClick = (markerData) => {
        setActiveMarker(markerData);
    };

    useEffect(() => {
        if (selectedPlace) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ placeId: selectedPlace.id }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    const location = results[0].geometry.location;
                    // setUserLocation({ lat: location.lat(), lng: location.lng() });
                    // setMapZoom(14);
                    updateCenter({ lat: location.lat(), lng: location.lng() })
                    updateZoom(12);
                }
            });
        }
    }, [selectedPlace]);

    const handlePlaceSelect = (place) => {
        if (place) {
            const { geometry } = place;
            if (geometry) {
                const { location } = geometry;
                updateCenter({ lat: location.lat(), lng: location.lng() })
                updateZoom(12);
            }
        }
    };

    const updateCenter = useCallback(newCenter => {
        setCameraState(prevState => {
            return {...prevState, center: newCenter}
        })
    }, [])

    const updateZoom = useCallback(newZoom => {
        setCameraState(prevState => {
            return {...prevState, zoom: newZoom}
        })
    }, [])

    return (
        <>
            <div className='bs-section bs-section--typ-grey bs-section--typ-title'>
                <div className='bs-section__section-head'>
                    <h3 className='bs-section__title'>
                        over <span className='cm-font-number'>350</span> stores, across <span className='cm-font-number'>100</span> cities
                    </h3>
                    <p className='bs-section__desc'>
                        Embracing the urgency of a sustainable future, we're committed to making our eco-conscious fabrics accessible across the nation. Visit your nearest store to explore our collections.
                    </p>
                </div>
            </div>
            <div className='bs-section'>
                <div className='lyt-locator'>
                    <div className='lyt-locator__head-wrap'>
                        <SearchFilter 
                            onDetectLocation={handleDetectLocation} 
                            // setSelectedPlace={setSelectedPlace} 
                            onPlaceSelect={handlePlaceSelect}
                        />
                        {/* <PlaceAutocomplete /> */}
                    </div>
                    <div className='lyt-locator__cont-wrap'>
                        <div className='lyt-locator__map-wrap'>
                            {/* {isLoaded ? (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={userLocation}
                                    zoom={mapZoom}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    mapId="570347ebde0a874f"
                                >
                                    <></>
                                </GoogleMap>
                            ) : <></>} */}
                            <APIProvider apiKey={"AIzaSyBKLFaOo3qUrrLmnWyjgbgnfcZTTfVNu20"}>
                                <Map
                                    style={{ width: '100%', height: '100%' }}
                                    id="map"
                                    gestureHandling={'greedy'}
                                    disableDefaultUI={true}
                                    zoomControl={false}
                                    {...cameraState}
                                    onCameraChanged={(ev) => {setCameraState(ev.detail)}}
                                    mapId="570347ebde0a874f"
                                >
                                    <CustomMapControl
                                        controlPosition={ControlPosition.TOP}
                                        onPlaceSelect={setSelectedPlace}
                                        selectedAutocompleteMode={selectedAutocompleteMode}
                                    />
                                    {/* <MapHandler place={selectedPlace} /> */}

                                    {storeListData.length > 0 && storeListData.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <MarkerWithInfowindow 
                                                    data={item}
                                                    isOpen={activeMarker && activeMarker.storeName === item.storeName}
                                                    onClick={handleMarkerClick}
                                                />
                                            </div>
                                        )
                                    })}
                                </Map>
                            </APIProvider>


                            <div className='lyt-locator__legend-wrap'>
                                <p className='lyt-locator__legend'>
                                    <span className='icon icon-pin lyt-locator__legend-icon lyt-locator__legend-icon--clr-orange'></span>
                                    <span className='lyt-locator__legend-text'>Exclusive Store</span>
                                </p>
                                <p className='lyt-locator__legend'>
                                    <span className='icon icon-pin lyt-locator__legend-icon'></span>
                                    <span className='lyt-locator__legend-text'>Multibrand Stores</span>
                                </p>
                            </div>
                        </div>

                        <div className='lyt-locator__result-wrap'>
                            <SearchResult data={storeListData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhereToBuy;
