import React from 'react';
import companyLogo from '../../assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';

function Footer() {

    const location = useLocation();

    return (
        <div className={`bs-footer ${location.pathname.includes('collections')  || location.pathname.includes('collection-detail') || location.pathname.includes('sku-detail') || location.pathname.includes('sustainability')  ? 'bs-footer--typ-dark' : ''}`}>
            <div className='bs-footer__logo-wrap'>
                <Link to="/" className='bs-footer__logo'>
                    <img src={companyLogo} alt="Sansaar logo" />
                </Link>
            </div>
            <div className='bs-footer__nav-wrap'>
                <nav className='bs-footer__nav'>
                    <ul className='bs-footer__nav-list'>
                        <li className='bs-footer__nav-item'>
                            <Link to="/our-journey" className={`bs-footer__nav-link ${location.pathname.includes('our-journey')  ? 'bs-footer__nav-link--active' : ''}`}>our journey</Link>
                        </li>
                        <li className='bs-footer__nav-item'>
                            <Link to="/collections" className={`bs-footer__nav-link ${location.pathname.includes('collections')  || location.pathname.includes('collection-detail')  || location.pathname.includes('sku-detail')  ? 'bs-footer__nav-link--active' : ''}`}>collections</Link>
                        </li>
                        <li className='bs-footer__nav-item'>
                            <Link to="/sustainability" className={`bs-footer__nav-link ${location.pathname.includes('sustainability')  ? 'bs-footer__nav-link--active' : ''}`}>sustainability</Link>
                        </li>
                        <li className='bs-footer__nav-item'>
                            <Link to="/faq" className={`bs-footer__nav-link ${location.pathname.includes('faq')  ? 'bs-footer__nav-link--active' : ''}`}>faqs</Link>
                        </li>
                        <li className='bs-footer__nav-item'>
                            <Link to="/contact-us" className={`bs-footer__nav-link ${location.pathname.includes('contact-us')  ? 'bs-footer__nav-link--active' : ''}`}>contact us</Link>
                        </li>
                        <li className='bs-footer__nav-item'>
                            <Link to="/privacy-policy" className={`bs-footer__nav-link ${location.pathname.includes('privacy-policy')  ? 'bs-footer__nav-link--active' : ''}`}>privacy policy</Link>
                        </li>
                        {/* <li className='bs-footer__nav-item'>
                            <Link to="#" className={`bs-footer__nav-link`}>career opportunities</Link>
                        </li> */}
                        {/* <li className='bs-footer__nav-item'>
                            <Link to="#" className={`bs-footer__nav-link`}>terms & conditions</Link>
                        </li> */}
                    </ul>
                </nav>
            </div>
            <div className='bs-footer__foot-wrap'>
                {/* <div className='bs-signup'>
                    <label className='bs-signup__label bs-footer__label'>sign up for updates</label>
                    <div className='bs-signup__form-group'>
                        <input className='bs-signup__field' placeholder="email address"/>
                        <button className='bs-btn bs-btn__btn-solid bs-signup__action'>Submit</button>
                    </div>
                </div> */}
                <div className='bs-social'>
                    <label className='bs-social__label bs-footer__label'>follow us</label>
                    <br></br>
                    <ul className='bs-social__link-wrap'>
                        <li className='bs-social__link-item'>
                            <Link to="https://www.instagram.com/sansaar.ddecorbrand" className='bs-social__link' target="_blank">
                                <span className='icon icon-instagram'></span>
                            </Link>
                        </li>
                        <li className='bs-social__link-item'>
                            <Link to="https://www.linkedin.com/company/sansaar" className='bs-social__link' target="_blank">
                                <span className='icon icon-linkedin'></span>
                            </Link>
                        </li>
                        <li className='bs-social__link-item'>
                            <Link to="https://www.youtube.com/@sansaar.ddecorbrand" className='bs-social__link' target="_blank">
                                <span className='icon icon-youtube'></span>
                            </Link>
                        </li>
                        <li className='bs-social__link-item'>
                            <Link to="https://www.facebook.com/profile.php?id=61557298044043" className='bs-social__link' target="_blank">
                                <span className='icon icon-facebook'></span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer