import React, { useEffect } from 'react';
import Instafeed from 'instafeed.js';


function InstafeedContainer() {

    useEffect(() => {
        // Initialize Instafeed
        const userFeed = new Instafeed({
          get: 'user',
          target: 'instafeed-container',
          resolution: 'low_resolution',
          limit: 6,
          accessToken: 'IGQWROVXdZAQTJsY3ZA2blhLdFB4dWhQOEtvdUdQVFd5cnBnZAnB2cFRBVXR4M2NqVmhfV2RNOThrcjlyOG5vV1BTVGNQOFgzaHlkaW5aLUctM1gyU21waURTWmJHSzk2VnVreXVpMXBGTjJPOEt3bUx5Sm1BYmt3cmcZD'
        });
    
        // Run Instafeed
        userFeed.run();
    }, []);


  return (
    <div className='lyt-img-list' id="instafeed-container"></div>
  )
}

export default InstafeedContainer