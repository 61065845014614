import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { loadGoogleMapsApi } from '../../../../../utils/loadGoogleMapsApi';

const API_KEY = 'AIzaSyBKLFaOo3qUrrLmnWyjgbgnfcZTTfVNu20'; // Replace with your Google Maps API key

function SearchFilter({ onDetectLocation, onPlaceSelect }) {
    const [google, setGoogle] = useState(null);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        loadGoogleMapsApi(API_KEY)
          .then((googleMaps) => {
            setGoogle(googleMaps);
          })
          .catch((error) => {
            console.error('Error loading Google Maps API', error);
          });
    }, []);

    const handlePlaceSelect = (event, value) => {
        if (value && google) {
          const placeService = new google.maps.places.PlacesService(document.createElement('div'));
          placeService.getDetails({ placeId: value.place_id }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                if (onPlaceSelect) {
                    onPlaceSelect(place);
                }
                // Clear the TextField
                setInputValue('');
            }
          });
        }
      };
    
      const handleInputChange = (event, value) => {
          if (google) {
            console.log('handleInputChange')
          const autocompleteService = new google.maps.places.AutocompleteService();
          autocompleteService.getPlacePredictions({ input: value }, (predictions, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              setOptions(predictions);
            }
          });
        }
      };

  return (
    <>
        <div className='lyt-locator__search-field-wrap'>
            <label className='lyt-locator__search-label'>Find the nearest store</label>
            <div className='lyt-locator__search-field'>
                <Autocomplete
                    freeSolo
                    options={options}
                    id="combo-box-demo"
                    value={null} // Reset value after selection
                    inputValue={inputValue} // Controlled input value
                    getOptionLabel={(option) => option.description || ''}
                    onChange={handlePlaceSelect}
                    onInputChange={(event, newValue) => {
                        setInputValue(newValue);
                        handleInputChange(event, newValue);
                    }}
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            placeholder='Area / Locality / Pincode'
                        />
                    }
                />
                {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={(option) => option.description || ''}
                    onChange={handlePlaceSelect}
                    onInputChange={handleInputChange}

                    renderInput={(params) => <TextField {...params} placeholder='Area / Locality / Pincode' />}
                    renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                            <Box
                                key={key}
                                component="li"
                                {...optionProps}
                            >
                                <span className='icon icon-pin'></span>
                                <span className='text'>{option.label}</span>
                            </Box>
                        );
                    }}
                /> */}
            </div>
        </div>
        <div className='lyt-locator__action-wrap'>
            {/* <button className='bs-btn bs-btn__btn-solid lyt-locator__btn'>Search</button> */}
            <span className='lyt-locator__separator'>or</span>
            <button className='bs-btn bs-btn__btn-solid lyt-locator__btn lyt-locator__btn--lg' onClick={onDetectLocation}>Detect my location</button>
        </div>
    </>
  )
}

export default SearchFilter