import React from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';


function Collections() {

    const cld = new Cloudinary({
        secure: true,
        cloud: {
            cloudName: 'dci1aiukm'
        }
    });

    const trendingCollectionData = [
        {
            collectionId: "B10002",
            name: 'luxe boucle',
            imgId: 'Luxe_boucle.jpg',
            url: "/collection-detail",
            description: "Cozy, rich textured weaves in soothing soft neutral colors, ideal for upholstery and drapery.",
            brochure_url: "https://sansaar-brochure-links.s3.amazonaws.com/LUXE+BOUCL%C3%89.pdf"
        },
        {
            collectionId: "B10020",
            name: "Budget Blackout",
            imgId: "Budget_blackout",
            url: "/collection-detail",
            description: "These fabrics provide 100% light blockage, enhancing privacy, sleep quality, and energy efficiency, ideal for curtains or blinds.",
            brochure_url: "https://sansaar-brochure-links.s3.amazonaws.com/BUDGET+BLACKOUT.pdf"
        },
        {
            collectionId: "B10006",
            name: 'locomotion',
            imgId: 'Locomotion.jpg',
            url: "/collection-detail",
            description: "Eco-friendly sheer fabrics crafted from recycled polyester, offering understated elegance.",
            brochure_url: "https://sansaar-brochure-links.s3.amazonaws.com/LOCOMOTION.pdf"
        },
        {
            collectionId: "B10004",
            name: 'Satina',
            imgId: 'Satina_yxcelb.jpg',
            url: "/collection-detail",
            description: "Sleek and lustrous, this recycled polyester collection offers a palette of sophisticated colors, perfect for endless creative expressions.",
            brochure_url: "https://sansaar-brochure-links.s3.amazonaws.com/SATINA.pdf"
        }
    ]

    return (
        <div className='bs-collections'>
            <Swiper
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                navigation={true}
                pagination={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Navigation, Pagination, Autoplay]}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                className="bs-collections__swiper-container"
            >
                {trendingCollectionData.map((item, index) => {

                    const imgSrc = cld.image(item.imgId).quality('auto');

                    return (
                        <SwiperSlide className='bs-collections__swiper-slide' key={index}>
                            <div className='bs-collections__item'>
                                <Link to={`${item.url}?id=${item.collectionId}`} className='bs-collection__link'></Link>
                                <AdvancedImage cldImg={imgSrc} className='bs-collections__image' />
                            </div>
                            <button className='bs-btn bs-btn__btn-solid'>{item.name}</button>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Collections