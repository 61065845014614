import React from 'react'
import SkuItem from '../../CollectionDetail/view/components/SkuItem'
import Detail from './components/Detail'
import { useNavigate, useSearchParams } from 'react-router-dom';

function SkuDetail() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const materialId = searchParams.get("id");
    const collectionData = JSON.parse(localStorage["collection-data"]);
    const materialData = collectionData?.MaterialDetails.filter((item) => {
        return item.materialCode === materialId;
    })
    const remainingMaterialData = collectionData?.MaterialDetails.filter((item) => {
        return item.qualityId === materialData[0]?.qualityId;
    })

    const scrollTopHandleChange = () => {
        window.scrollTo(0, 0);
    }

  return (
    <>
        <div className='bs-section bs-section--typ-grey cm-no-padding'>
            <div className='bs-section__section-cont'>
                <Detail data={collectionData} materialData={materialData[0]} />
            </div>
        </div>
        <div className='bs-section'>
            <div className='bs-section__section-cont'>
                <div className='lyt-product'>
                    <div className='lyt-product__title-wrap'>
                        <h2 className='lyt-product__title'>All Colours</h2>
                    </div>
                    <ul className='lyt-product__list-wrap'>
                        {remainingMaterialData.map((item, index) => {
                            if(item?.materialCode !== materialData[0]?.materialCode) {
                                return (
                                    <li className='lyt-product__list-item' key={index} onClick={scrollTopHandleChange}>
                                        <SkuItem
                                            imageId={item.skuImageUrl}
                                            link={`/sku-detail?id=${item?.materialCode}`}
                                            title={collectionData?.name + ", Sr.No. " + item.serialNo}
                                        />
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default SkuDetail