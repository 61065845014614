import '../src/common/assets/styles/custom.scss';
import {useEffect, useState} from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "@common/components/ScrollToTop"
import Header from "./common/components/Header"
import Footer from "./common/components/Footer"
import Home from './pages/Home/view';
import Contact from './pages/Contact/view';
import OurJourney from './pages/OurJourney/view';
import PageLoader from './pages/Home/view/components/PageLoader';
import Collections from './pages/Collection/view';
import CollectionDetail from './pages/CollectionDetail/view';
import SkuDetail from './pages/SkuDetail/view';
import Faq from './pages/Faq/view';
import PrivacyPolicy from './pages/PrivacyPolicy/view';
import WhereToBuy from '@pages/WhereToBuy/view';
import Sustainability from './pages/Sustainability/view';

const MainContainer = ({ children }) => {
  const location = useLocation();
  
  const getClassForRoute = () => {
    return location.pathname.includes('collections') ? 'lyt-content__tpl-collection' : ''
  };

  return (
    <div className={`lyt-content ${getClassForRoute()}`}>
      {children}
    </div>
  );
}


function App() {

  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)
    },[])

  return (
    isLoading && window.location.pathname === '/' ? (
      <PageLoader/>
  ) : (
    <Router>
      <ScrollToTop />
      <div>
        <Header />
        <main>
          <MainContainer>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/our-journey" element={<OurJourney />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/collection-detail" element={<CollectionDetail />} />
              <Route path="/sku-detail" element={<SkuDetail />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/where-to-buy" element={<WhereToBuy />} />
              <Route path="/sustainability" element={<Sustainability />} />
            </Routes>
          </MainContainer>
        </main>
        <Footer/>
      </div>
    </Router>
  )
  );
}

export default App;
