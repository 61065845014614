import React, { useEffect, useState } from 'react';
import { MapControl } from '@vis.gl/react-google-maps';
import { Autocomplete, TextField, Box } from '@mui/material';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { PlaceAutocompleteClassic } from '../PlaceAutocompleteClassic';
import { AutocompleteCustom } from '../AutocompleteCustom';
import { AutocompleteCustomHybrid } from '../AutocompleteCustomHybrid';

export const CustomMapControl = ({ controlPosition, onPlaceSelect, selectedAutocompleteMode }) => {
  const {id} = selectedAutocompleteMode;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (places) {
      const autocompleteService = new places.AutocompleteService();

      autocompleteService.getPlacePredictions({ input: value, types: ['geocode'] }, (predictions) => {
        if (predictions) {
          setOptions(predictions.map(prediction => ({
            label: prediction.description,
            placeId: prediction.place_id
          })));
        }
      });
    }
  }, [value, places]);

  const handleChange = (event, newValue) => {
    if (newValue && newValue.placeId) {
      const placesService = new places.PlacesService(document.createElement('div'));
      placesService.getDetails({ placeId: newValue.placeId }, (place) => {
        if (place) {
          onPlaceSelect(place);
        }
      });
    }
    setValue(newValue ? newValue.label : '');
  };

  return (
    <MapControl position={controlPosition}>
      <div className="autocomplete-control lyt-locator__autocomplete">
        {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={handleChange} 
            onInputChange={(event, newInputValue) => setValue(newInputValue)}
            options={options}
            renderInput={(params) => <TextField {...params} placeholder='Area / Locality / Pincode' />}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <Box
                        key={key}
                        component="li"
                        {...optionProps}
                    >
                    <span className='icon icon-pin'></span>
                    <span className='text'>{option.label}</span>
                    </Box>
                );
            }}
        /> */}
        {id === 'classic' && (
          <PlaceAutocompleteClassic onPlaceSelect={onPlaceSelect} />
        )}

        {id === 'custom' && (
          <AutocompleteCustom onPlaceSelect={onPlaceSelect} />
        )}

        {id === 'custom-hybrid' && (
          <AutocompleteCustomHybrid onPlaceSelect={onPlaceSelect} />
        )}
      </div>
    </MapControl>
  );
};
