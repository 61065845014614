import React from 'react';

const addressData = [
    {
        id: 1,
        storeType: "exclusive store",
        address: "Address: Shop no 1, W, Vallabh Shopping Centre, Swami Vivekananda Rd, Meghdoot, Mhatre Wadi, Borivali, Mumbai, Maharashtra 400092",
        openTime: "Hours: Opens 10am & Closes 9 pm",
        distance: "2.3 Kms"
    },
    {
        id: 2,
        storeType: "exclusive store",
        address: "Address: Shop No. 9 & 10, Sunil Niwas, Lokhandwala Road, Off, JP Rd, Andheri West, Mumbai, Maharashtra 400053",
        openTime: "Hours: Opens 10am & Closes 9 pm",
        distance: "2.3 Kms"
    },
    {
        id: 3,
        storeType: "exclusive store",
        address: "Address: Sheth Avalon, Shop no.03 Ground Floor, Building-G Survey No.73/1, near Jupiter Hospital, Mumbai, Maharashtra 400601",
        openTime: "Hours: Opens 10am & Closes 9 pm",
        distance: "2.3 Kms"
    },
    {
        id: 4,
        storeType: "exclusive store",
        address: "Address: Sheth Avalon, Shop no.03 Ground Floor, Building-G Survey No.73/1, near Jupiter Hospital, Mumbai, Maharashtra 400601",
        openTime: "Hours: Opens 10am & Closes 9 pm",
        distance: "2.3 Kms"
    },

]

function SearchResult({ data }) {
    const eboStores = (data.filter(store => store.storeType === 'EBO')).sort((a, b) => a.rank - b.rank);
    const mboStores = (data.filter(store => store.storeType === 'MBO')).sort((a, b) => a.rank - b.rank);

  return (
    <div className='bs-search-results'>
        <label className='bs-search-results__title'>Search results</label>
        <ul className='bs-search-results__list-wrap'>
            {eboStores.map((item, index) => {
                return (
                    <li className='bs-search-results__list-item' key={index}>
                        <div className='bs-search-results__item'>
                            {index === 0 ? (<h3 className='bs-search-results__item-title'>{'exclusive stores'}</h3>) : null}
                            <p className='bs-search-results__item-time'>{item.storeName}</p>
                            <address className='bs-search-results__item-address'>{`${item.fullAddress} ${item.city} ${item.state} ${item.pinCode}`}</address>
                            <a href={`tel:${item.contactNumber}`} className='bs-search-results__item-time'>Contact - {item.contactNumber}</a>
                            {/* <p className='bs-search-results__item-time'>{item.openTime}</p> */}
                            {/* <strong className='bs-search-results__item-distance'>Link - {item.googleMapLink}</strong> */}
                        </div>
                    </li>
                )
            })}
            {mboStores.map((item, index) => {
                return (
                    <li className='bs-search-results__list-item' key={index}>
                        <div className='bs-search-results__item'>
                            {/* {index === 0 ? (<h3 className='bs-search-results__item-title'>{'multibrand stores'}</h3>) : null} */}
                            <p className='bs-search-results__item-time'>{item.storeName}</p>
                            <address className='bs-search-results__item-address'>{`${item.fullAddress} ${item.city} ${item.state} ${item.pinCode}`}</address>
                            <a href={`tel:${item.contactNumber}`} className='bs-search-results__item-time'>Contact - {item.contactNumber}</a>
                            {/* <p className='bs-search-results__item-time'>{item.openTime}</p> */}
                            {/* <strong className='bs-search-results__item-distance'>Link - {item.googleMapLink}</strong> */}
                        </div>
                    </li>
                )
            })}
        </ul>
    </div>
  )
}

export default SearchResult