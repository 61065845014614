import React, { useRef, useState } from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { EffectFade, Navigation, Autoplay } from 'swiper/modules';
import videoUrl from '../../assets/videos/home_video_banner_10_mb.mp4';
import videoPosterImg from '../../assets/images/banner-video-poster.jpg';
import { Link } from 'react-router-dom';

function Banner({variant, imgId, type}) {

    const videoRef = useRef();
    const [muteState, setMuteState] = useState(true)

    const muteUnmuteHandleChange = () => {
        if(muteState){
            setMuteState(false)
        }else{
            setMuteState(true)
        }
    }

    const cld = new Cloudinary({
        cloud: {
          cloudName: 'dci1aiukm'
        }
      });
    
    const myImage = cld.image(imgId).quality('auto');

  return (
    <div className={`bs-banner ${variant === 'video' ? 'bs-banner--typ-video' : ''} ${type === 'small' ? 'bs-banner--typ-small' : '' }`}>
        {variant === 'video' ? (
            <div className='bs-banner__video-wrap'>
                <video 
                    controls={false} 
                    poster={videoPosterImg} 
                    autoPlay={true}
                    playsInline={true}
                    webkit-playsinline={true}
                    muted={muteState}
                    loop={true}
                    className='bs-banner__video'
                    ref={videoRef}
                >
                    <source src={videoUrl} type="video/mp4" />
                </video>
                <div className='bs-banner__slide-info'>
                    <Link to={"/collections"}className='bs-btn bs-btn__btn-solid bs-btn__btn-solid--inverse'>explore now</Link>
                </div>
                <button className='bs-banner__video-wrap__action' onClick={muteUnmuteHandleChange}>
                    {!muteState ? (
                        <span className='icon icon-unmute'></span>
                    ) : (
                        <span className='icon icon-mute'></span>
                    )}
                </button>
            </div>
        ) : variant === 'slide' ? (
            <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[EffectFade, Navigation, Autoplay]}
            className="bs-banner__swiper-container"
        >
            <SwiperSlide className='bs-banner__swiper-slide'>
                <div className='bs-banner__slide-image'>
                    <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                </div>
                <div className='bs-banner__slide-info'>
                    <h2 className='bs-banner__slide-title'>This is my Sansaar</h2>
                    <button className='bs-btn bs-btn__btn-solid bs-btn__btn-solid--inverse'>explore now</button>
                </div>
            </SwiperSlide>
            <SwiperSlide className='bs-banner__swiper-slide'>
                <div className='bs-banner__slide-image'>
                    <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                </div>
                <div className='bs-banner__slide-info'>
                    <h2 className='bs-banner__slide-title'>This is my Sansaar 2</h2>
                    <button className='bs-btn bs-btn__btn-solid bs-btn__btn-solid--inverse'>explore now</button>
                </div>
            </SwiperSlide>
            <SwiperSlide className='bs-banner__swiper-slide'>
                <div className='bs-banner__slide-image'>
                    <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                </div>
                <div className='bs-banner__slide-info'>
                    <h2 className='bs-banner__slide-title'>This is my Sansaar 3</h2>
                    <button className='bs-btn bs-btn__btn-solid bs-btn__btn-solid--inverse'>explore now</button>
                </div>
            </SwiperSlide>
            <SwiperSlide className='bs-banner__swiper-slide'>
                <div className='bs-banner__slide-image'>
                    <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                </div>
                <div className='bs-banner__slide-info'>
                    <h2 className='bs-banner__slide-title'>This is my Sansaar 4</h2>
                    <button className='bs-btn bs-btn__btn-solid bs-btn__btn-solid--inverse'>explore now</button>
                </div>
            </SwiperSlide>
            </Swiper>
        ) : (
            <div className='bs-banner__media'>
                <AdvancedImage cldImg={myImage} className='bs-banner__image' />
            </div>
        )}
    </div>
  )
}

export default Banner