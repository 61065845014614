// const BASE_URL = "https://sansaar.ainocular.com/api/v1"
const BASE_URL = "https://sansaar.co.in/api/v1"

const apiUrl = {
    collection: {
        getAll: `${BASE_URL}/collection/all`,
        getMaterials: (collectionId) => `${BASE_URL}/collection/get-materials/${collectionId}`,
        getTrending: `${BASE_URL}/collection/trending`
    },
    contactUs: `${BASE_URL}/util/contact-form`,
    store: `${BASE_URL}/util/store`

};

export default apiUrl;